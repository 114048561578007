<template>
    <div class="pageing">
        <div class="kong"></div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10]" :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="count"
            background>
        </el-pagination>
    </div>
</template>

<script>
export default {
    props: ['count', 'initData'],
    data () {
        return {
            currentPage: 1,
            max: 10,
            page: 1,
        };
    },
    methods: {
        handleSizeChange (val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange (val) {
            // console.log(`当前页: ${val}`);
            this.page = val;
            this.$emit('changePage', this.page)
            this.initData();
        }
    },
}
</script>>

<style lang="scss" scoped>
.pageing {
    width: 100%;
    // margin-top: 20px;
    // text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .kong {
        width: 10px;
    }
    .el-pagination {
        height: 44px;
        background: #6a8be8;
        border-radius: 20px 0 20px 20px;
        padding-top: 16px;
        /deep/.el-pager li {
            width: 24px;
            height: 30px;
            line-height: 30px;
            border-radius: 50%;
            font-size: 14px;
            font-weight: 400;
        }
        ::v-deep {
            .el-pager li:not(.disabled) {
                background-color: #6a8be8; // 进行修改未选中背景和字体
                color: #fff;
            }
            .el-pager li:not(.disabled).active {
                background-color: #ffffff; // 进行修改选中项背景和字体
                color: #6a8be8;
            }
        }
        // 左右按钮跳转
        /deep/button {
            width: 24px;
            height: 30px;
            line-height: 44px;
            border-radius: 50%;
            background: #6a8be8;
            text-align: center;
            padding: 0;
            i {
                font-size: 14px;
                color: #ffffff;
            }
        }
        // 总数隐藏
        /deep/.el-pagination__total {
            display: none;
        }
        // 下拉框隐藏
        /deep/.el-pagination__sizes {
            display: none;
        }
        // 前往第几页隐藏
        /deep/.el-pagination__jump {
            display: none;
        }
    }
}
</style>